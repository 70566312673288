<template>
  <div class="bmi_input">
    <h1>BMI計算機</h1>
    <v-form>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="m_height_cm"
              label="身長(cm)"
              :rules="[check_require, check_length, is_number]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="m_weight_kg"
              label="体重(kg)"
              :rules="[check_require, check_length, is_number]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-btn @click="CalulateBmi">計算</v-btn>

    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">BMI</th>
            <th class="text-left">肥満度</th>
            <th class="text-left">適正体重</th>
            <th class="text-left">適正体重と比較</th>
            <th class="text-left">BMI</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in m_bmiInfos" :key="item.bmiRange">
            <td>{{ item.bmiRange }}</td>
            <td>{{ item.ponderal }}</td>
            <td>{{ item.fitlyWeight }}</td>
            <td>{{ item.weightGap }}</td>
            <td>{{ item.bmi }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "BmiInput",
  data: () => ({
    m_weight_kg: "",
    m_height_cm: "",
    check_require: (value) => !!value || "必須項目です",
    check_length: (value) => value.length <= 3 || "3桁以内で入力してください",
    is_number: (value) => !isNaN(value) || "半角数字で入力してください",
    m_bmiInfos: [
      {
        bmiRange: "18.5未満",
        ponderal: "低体重",
        fitlyWeight: null,
        weightGap: null,
        bmi: null,
      },
      {
        bmiRange: "18.5～25未満",
        ponderal: "普通体重",
        fitlyWeight: null,
        weightGap: null,
        bmi: null,
      },
      {
        bmiRange: "25～30未満",
        ponderal: "肥満(I)",
        fitlyWeight: null,
        weightGap: null,
        bmi: null,
      },
      {
        bmiRange: "30～35未満",
        ponderal: "肥満(II)",
        fitlyWeight: null,
        weightGap: null,
        bmi: null,
      },
      {
        bmiRange: "35～40未満",
        ponderal: "肥満(III)",
        fitlyWeight: null,
        weightGap: null,
        bmi: null,
      },
      {
        bmiRange: "40以上",
        ponderal: "肥満(IV)",
        fitlyWeight: null,
        weightGap: null,
        bmi: null,
      },
    ],
  }),
  methods: {
    CalulateBmi() {
      {
        if( this.m_weight_kg === '' || 
          this.m_height_cm === '' ) {
            alert("身長・体重を入力してください。");
            return;
        }
      }
      {
        // 入力済みデータ削除
        this.m_bmiInfos.forEach((item) => {
          item.fitlyWeight = null;
          item.weightGap = null;
          item.bmi = null;
        });
      }

      // BMI計算、表示反映
      {
        // BMI = weight(kg) * (height(m))^2
        const height_m = this.m_height_cm / 100; // cm→mに変換
        const bmi =
          Math.round((this.m_weight_kg / Math.pow(height_m, 2)) * 10) / 10; // 小数第2位で四捨五入のため*10
        const bmiIndex = this._GetBmiIndex(bmi);

        // 標準体重 = 身長(m)^2 * 22
        const fitlyWeightKg =
          Math.round(Math.pow(height_m, 2) * 22.0 * 10) / 10; // 小数第2位で四捨五入のため*10
        const gapWeight = Math.round((this.m_weight_kg - fitlyWeightKg) * 10) / 10;
        this.m_bmiInfos[bmiIndex].bmi = bmi;
        this.m_bmiInfos[bmiIndex].fitlyWeight = fitlyWeightKg + "kg";
        this.m_bmiInfos[bmiIndex].weightGap =gapWeight + "kg";
      }
    },
    _GetBmiIndex(bmi) {
      if (bmi < 18.5) {
        return 0;
      } else if (bmi < 25.0) {
        return 1;
      } else if (bmi < 30.0) {
        return 2;
      } else if (bmi < 35.0) {
        return 3;
      } else if (bmi < 40.0) {
        return 4;
      } else {
        return 5;
      }
    },
  },
};
</script>

<style scoped>
</style>
