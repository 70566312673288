<template>
  <div id="app">
    <v-container style="align-center">
      <v-row >
        <v-col cols="12" sm="6">
          <BmiInput />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BmiInput from "./components/Input.vue";

export default {
  name: "App",
  components: {
    BmiInput,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
